const $ = require('jquery');
global.$ = global.jQuery = $;

// require('@splidejs/splide/dist/css/splide.min.css');
// import { Splide } from '@splidejs/splide';
// import { Grid } from '@splidejs/splide-extension-grid';
// global.Splide = Splide;
// global.Grid = Grid;

// require('bootstrap');
